import { useState, useEffect } from "react";
import Grid from "@mui/material/Grid";
import FeedCard from "../Card";
import FeedCardFlex from "../CardFlex";
import FeedDrawer from "../Drawer";
import useWindowSize from "../../hooks/useResize";
import useResizeReadings from "../../hooks/useResizeReadings";
import ErrorPage from "../Error";
import Highlights from "../Highlights/Highlights";
import ComponentLoader from "../Loader/ComponentLoader";
import {
  CheckDrawerHighlightProvider,
  CheckHighlightFromDrawerProvider,
} from "../../hooks/useContext";
import Ad from "../../Ad";
import NoContent from "../../NoContent";
import Carousel from "../Carousel";
import axios from "axios";
import ReactGA from "react-ga";
import * as Sentry from "@sentry/react";

const Feed = ({
  feedResults,
  highlights,
  algoliaQueryId,
  highlightErrorOccured,
}) => {
  const windowWidth = useWindowSize();
  const resizedResults = useResizeReadings(feedResults, windowWidth);
  const [open, setOpen] = useState(false);
  const [carouselResults, setCarouselResults] = useState();
  const [sponsorError, setSponsorError] = useState();

  const handleDrawer = (data) => {
    if (data) {
      window.analytics.page("Conference Daily More News Drawer");
      ReactGA.pageview("Conference Daily More News Drawer");
    }
    setOpen(data);
  };

  useEffect(() => {
    axios(`/api/getSponsors`)
      .then((response) => {
        if (response.data.type === "CAROUSEL" && response.status === 200) {
          setCarouselResults(response.data.images);
        }
      })
      .catch((err) => {
        setSponsorError(true);
        Sentry.captureException(new Error(err), {
          tags: {
            section: "Netlify Function Sponsors",
          },
        });
      });
  }, []);

  const sorted = resizedResults.mainResults
    .sort((a, b) => {
      return new Date(b.publishDate) - new Date(a.publishDate);
    })
    .filter((res) => res.objectID !== resizedResults.featuredResult.objectID);

  const carouselContent = () => {
    return (
      <Grid
        sx={{
          paddingTop: "10px",
          paddingBottom: "10px",
          width: "100%",
        }}
      >
        {carouselResults !== undefined ? (
          <Carousel data={carouselResults} />
        ) : sponsorError ? (
          <ErrorPage />
        ) : (
          <ComponentLoader />
        )}
        {sorted.length <= 7 ? (
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
              paddingTop: "10px"
            }}
          >
            <Ad />
          </Grid>
        ) : null}
      </Grid>
    );
  };

  const highlightsContent = () => {
    return (
      <Grid>
        <CheckDrawerHighlightProvider>
          <CheckHighlightFromDrawerProvider>
            {highlights !== undefined ? (
              <Highlights results={highlights} />
            ) : highlightErrorOccured ? (
              <ErrorPage />
            ) : (
              <ComponentLoader />
            )}
          </CheckHighlightFromDrawerProvider>
        </CheckDrawerHighlightProvider>
        <Grid
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Ad />
        </Grid>
      </Grid>
    );
  };

  const feedSequence = (result, index) => {
    if (index === 3) {
      return (
        <Grid
          sx={{
            margin: "0 auto",
            width: "100%",
          }}
        >
          {highlightsContent()}
          <FeedCard
            data={result}
            queryId={algoliaQueryId}
            indexPosition={index}
          />
          {sorted.length === 4 ? carouselContent() : null}
        </Grid>
      );
    } else if (index === 6) {
      return (
        <>
          {carouselContent()}
          <FeedCard
            data={result}
            queryId={algoliaQueryId}
            indexPosition={index}
          />
        </>
      );
    } else if (index === 7) {
      return (
        <Grid sx={{ margin: "0 auto", width: "100%" }}>
          <Grid
            sx={{
              display: "flex",
              justifyContent: "center",
            }}
          >
            <Ad />
          </Grid>
          <FeedCard
            data={result}
            queryId={algoliaQueryId}
            indexPosition={index}
          />
        </Grid>
      );
    } else {
      return (
        <FeedCardFlex
          data={result}
          queryId={algoliaQueryId}
          indexPosition={index}
        />
      );
    }
  };

  return (
    <Grid>
      {sorted === undefined || feedResults === undefined ? (
        <ErrorPage />
      ) : (
        <div>
          <Grid>
            {sorted.length !== 0 ? (
              sorted.map((result, index) => {
                return feedSequence(result, index);
              })
            ) : (
              <Grid sx={{ display: "flex", margin: "0 auto" }}>
                <NoContent />
              </Grid>
            )}
            {sorted.length < 4 ? highlightsContent() : null}
            {sorted.length < 4 || sorted.length === 5 || sorted.length === 6
              ? carouselContent()
              : null}
          </Grid>
          {resizedResults.showMore === true ? (
            <FeedDrawer
              data={feedResults}
              modifiedResults={resizedResults.mainResults}
              topStoryLatest={resizedResults.featuredResult}
              open={open}
              handler={handleDrawer}
              queryId={algoliaQueryId}
            />
          ) : null}
        </div>
      )}
    </Grid>
  );
};

export default Feed;
