export const strings = {
  GLOBAL: {
    MY_ATD: 'MyATD',
    BOOKMARK_DELETED: 'Bookmark successfully deleted!',
    BOOKMARK_ADDED: 'Bookmark successfully added!',
  },
  VALIDATION: {
    TOO_SHORT: 'Too Short!',
    TOO_LONG: 'Too Long!',
    REQUIRED: 'Required',
    INVALID_EMAIL: 'Invalid email',
  },
  COOKIE_BANNER: {
    DESCRIPTION:
      'We use cookies to personalize content and ads, to provide social media features and to analyze our traffic. By closing this banner you are accepting cookies.',
    CTA: 'Click here to learn more about our cookie policy.',
  },
  MENU: {
    HELP: 'Help',
    MY_ACCOUNT: 'My Account',
    MY_ORDERS: 'My Orders and Downloads',
    SIGN_OUT: 'Sign Out',
    MY_BOOKMARKS: 'My Bookmarks',
    BECOME_A_MEMBER: 'Become A Member',
    FORGOT_PASSWORD: 'Forgot your password?',
  },
  LOGIN: {
    LOGIN: 'Login',
    REGISTER: 'Register',
    INSTITUTIONAL_SUBSCRIBER_CTA:
      'Institutional Subscriber? Click here to sign in.',
  },
  NAV: {
    NOT_FOUND_MESSAGE: '404 not found',
    BACK_TO_HOME: 'go back home',
    ATD_GLOBAL: 'ATD Global',
    CHAPTERS: 'Chapters',
    ENTERPRISE_SOLUTIONS: 'Enterprise Solutions',
    ATD_PHONE_GLOBAL: '+1.800.628.2783',
    ATD_CONTACT: 'Contact Us',
  },
  ERRORS: {
    GENERIC_ERROR: 'Generic error text',
    GENERIC_UPDATE_ERROR: 'There was a problem adjusting your settings',
  },
  SUCCESS: {
    GENERIC_UPDATE_SUCCESS: 'Update successful',
    GENERIC_VARIABLE_UPDATE_SUCCESS: '%s settings updated',
  },
  MISSION: {
    DESCRIPTION:
      'Our Mission: Empower Professionals to Develop Talent in the Workplace',
  },
  PROFILE: {
    ACCESS: {
      PRIMARY_EMAIL: 'Email',
      PRIMARY_EMAIL_PLACEHOLDER: 'Enter your email address.',
      CURRENT_PASSWORD: 'Current Password',
      CURRENT_PASSWORD_PLACEHOLDER: 'Enter your current password.',
      NEW_PASSWORD: 'New Password',
      NEW_PASSWORD_PLACEHOLDER: 'Enter your new password.',
      FORGOT_PASSWORD: 'Forgot your password?',
      PASSWORD_STRENGTH_DESC: `Your new password must be at least <strong>seven characters</strong> long and have at least <strong>one numeric character</strong>.`,
      PASSWORD_RETYPE: 'Retype New Password',
      PASSWORD_RETYPE_PLACEHOLDER: 'Please retype your new password.',
      TITLE: 'Account Access',
      TITLE_EDIT: 'Edit Account Access',
      INDUSTRY: 'Choose your industry.',
      ROLE: 'Choose your role.',
      JOB: 'Enter your job title.',
      C_NAME: 'Enter your company name.',
      C_SIZE: 'Choose your company size.',
      ADDRESS: 'Enter your full address.',
      BUDGET: 'Choose your training budget.',
      OPTION: 'Choose an option.',
      DEGREE: 'Choose your degree type.',
      PROGRAM: 'Choose your program type.',
      SCHOOL: 'Enter your school name.',
      YEAR: 'Enter your graduation year.',
    },
    MEMBERSHIP: {
      MORE_INFO: 'For more information about your member benefits, visit the',
      BENEFITS_CENTER: 'Benefits Center',
      SITES: 'My Sites & Resources',
    },
    VISIBILITY: {
      MAKE_PUBLIC: 'Make my profile public.',
      INCLUDE_IN_MEMBER_DIR: 'Include me in the member directory.',
      INCLUDE_IN_MEMBER_DIR_HELP:
        'Your name, location, job title and company will appear in the online Member Directory. Users will be able to contact you via the Directory.',
      VISIBILITY_AVAILABLE_WARNING:
        'Your name, photo and bio will be visible to ATD community.',
      MAKE_PRIVATE_WARNING:
        'Making your profile private means you will no longer be able to contribute to the site, but any content you have previously contributed will remain with your name in the byline unless you delete your contributions individually. Additionally, you can no longer be followed by other members of the community.',
      MAKE_PUBLIC_WARNING:
        'Making your profile public means you will once again be able to contribute to the site if you are a member. Additionally, the ability to be followed by other members of the community will be restored.',
    },
    BIO: {
      PROFILE_IS_ON: 'Your Public Profile is ON',
      PROFILE_IS_OFF: 'Your Public Profile is OFF',
      VISIBILITY_AVAILABLE_WARNING:
        'The following will be visible to other users',
      VISIBILITY_AVAILABLE_WARNING_OFF:
        'The following will be not visible to other users',
      PROFILE_PICTURE: 'Profile Picture',
      ERROR_MORE_THAN_ONE_FILE: 'More then one image was uploaded!',
      ERROR_NOT_AN_IMAGE: 'Uploaded file was not an image!',
      ERROR_UPLOAD_SIZE: 'Uploaded image exceeds file size limit!',
      FIRST_NAME: 'First Name',
      ENTER_FIRST_NAME: 'Enter your first name.',
      LAST_NAME: 'Last Name',
      ENTER_LAST_NAME: 'Enter your last name.',
      BIO: 'Biography',
      BIO_SHORT: 'Bio',
      BIO_EDITING: 'Editing Bio',
      BIO_PROVIDE_DESCRIPTION: 'Provide a brief description of yourself.',
    },
    NETWORK: {
      SUGGESTED_USERS_TITLE: 'Suggested for you',
      SUGGESTED_USERS_SUBTITLE:
        "Connect with leaders in the industry, professionals with a role similar to yours, and thousands of other ATD users. When you follow somebody, you'll be able to see what they are reading, learning, sharing and discussing.",
      FOLLOWING: 'Following',
      FOLLOWERS: 'Followers',
      GROUPS: 'Groups',
    },
    ORDERS: {
      AUTO_RENEWAL_ENDED: 'Your auto-renewal has been ended.',
      AUTO_RENEWAL_NOTE: `Your order will automatically renew annually and the payment card you provide today will be charged at the time of the renewal. Upon your next auto-renewal payment and annually thereafter so long as you’re opted in, a 10% discount will be applied to your ATD national membership or your joint ATD chapter and national membership purchase. Please note that you cannot auto-renew chapter-only memberships on td.org.`,
      AUTO_RENEWAL_DISCONTINUED:
        'Auto-renewal has been discontinued for the following Membership:',
      AUTO_RENEWAL_ENABLE_CTA:
        'You will no longer receive a 10% discount on your ATD national membership or your joint ATD chapter and national membership purchase. If you wish to turn auto-renewal back on, you can do so at any time by toggling auto-renew on.',
      OKAY: 'Okay',
      NO_ORDERS: `It appears you don't have any orders here.`,
      REVIEW_ORDER_HISTORY:
        'Review your order history, download digital orders, and more.',
    },
    COMMUNICATION: {
      EDIT_NOTIFICATIONS: 'Editing notification settings',
      OPTION_CHOOSE_EMAIL_UPDATES:
        'Choose the e-mail updates you wish to receive',
      FEWER_EMAILS: `If you'd like fewer emails, select an option below`,
      SELECT_POSTAL_MAIL: 'Select your postal mail preferences',
      SELECT_PHONE: `Select your phone preferences`,
    },
  },
  ONBOARDING: {
    WELCOME_USER: 'Hi %s, welcome to the new MyATD.',
    WELCOME_DESCRIPTION:
      'Help us better customize your experience by verifying the information we have on file and completing any missing pieces.',
    CREATE_ACCOUNT: {
      HEADER: {
        TITLE: 'Create Your Account',
        SUBTITLE:
          'The following information is required to create your MyATD account',
      },
      HEADER_LOGGED_IN_USER: {
        TITLE_START: 'Hi',
        TITLE_REST: 'Welcome to the new MyATD.',
        SUBTITLE:
          'Help us better customize your experience by verifying the information we have on file and completing any missing pieces.',
      },
      ACCOUNT: {
        TITLE: 'Account Information',
        SUBTITLE: '',
      },
      PROFESSIONAL: {
        TITLE: 'Professional Information',
        SUBTITLE: 'Choose the options most applicable to you.',
      },
      CONTACT: {
        TITLE: 'Contact Information',
        SUBTITLE: 'Choose the options most applicable to you.',
      },
      BUTTON_LABEL: 'Save and Continue',
      NEXT_STEP:
        'Next, complete your profile and set up your preferences to get the most out of your ATD experience.',
      MODAL: {
        WELCOME: 'Welcome to ATD.',
        GET_STARTED: 'Get Started',
        SKIP: 'Skip',
      },
    },
    PROFILE: {
      HEADER: {
        TITLE: 'Profile Settings & Bio',
        SUBTITLE: '',
      },
    },
    WORK: {
      HEADER: {
        TITLE: 'Work',
        SUBTITLE: '',
      },
    },
    EDUCATION: {
      HEADER: {
        TITLE: 'Education',
        SUBTITLE: '',
      },
    },
    CONTACT: {
      HEADER: {
        TITLE: 'Contact',
        SUBTITLE: '',
      },
    },
    COMMUNICATION: {
      HEADER: {
        TITLE: 'Communication Preferences',
        SUBTITLE: '',
      },
      MODAL_KEEP_SELECTION: 'No, I would like to keep my selections.',
      MODAL_CONFIRM: 'Yes, I am sure.',
      MODAL_CONFIRM_UNSUBSCRIBE_PROMPT:
        'Are you sure you would like to unsubscribe from all emails?',
      MODAL_CONFIRM_DIGEST_PROMPT: `Are you sure you'd only like to receive ATD's Bimonthly News Roundup?`,
      MODAL_CONFIRM_CLEAR_SELECTION:
        'Doing so will clear all of your current newsletter selections.',
      MODAL_CONFIRM_CLEAR_SELECTION_DETAIL:
        ' Please note: Order confirmations and other emails to fulfill transactions and purchases will continue to be sent.',
      SELECT_POSTAL_MAIL: 'Select your postal mail preferences',
      SELECT_PHONE_OPTION: `Select your phone preferences`,
      OPTION_POSTAL_GET: 'I DO wish to receive communications via postal mail.',
      OPTION_POSTAL_NONE:
        'I DO NOT wish to receive communications via postal mail.',
      OPTION_PHONE_NONE: `I DO NOT wish to receive communications via phone.`,
      OPTION_PHONE_GET: `I DO wish to receive communications via phone.`,
      OPTION_CHOOSE_EMAIL_UPDATES:
        'Choose the e-mail updates you wish to receive',
      OPTION_CHOOSE_FEWER_EMAILS:
        "If you'd like fewer emails, select an option below",
      OPTION_MORE_TITLE: 'Choose the e-mail updates you wish to receive:',
      OPTION_LESS_TITLE: 'If you’d like fewer emails, select an option below:',
      OPTION_LESS_ONE_LABEL: "Only send me ATD's Bimonthly News Roundup",
      OPTION_LESS_ONE_DESCRIPTION:
        'You’ll only get two emails each month with the most important and timely information. In addition, order confirmations and other emails to fulfill transactions and purchases will continue to be sent.',
      OPTION_LESS_TWO_LABEL: 'Unsubscribe From All',
      OPTION_LESS_TWO_DESCRIPTION:
        'Please note that order confirmations and other emails to fulfill transactions and purchases will continue to be sent.',
    },
    NETWORK: {
      HEADER: {
        TITLE: 'Build Your Network',
        SUBTITLE: '',
      },
      NAVIGATION: {
        PREVIOUS_LABEL: 'Communication Preferences',
        NEXT_LABEL: 'Follow Your Topic',
      },
      TITLE: '',
      DESCRIPTION:
        "Connect with leaders in the industry, professionals with a role similar to yours, and thousands of other ATD users. When you follow somebody, you'll be able to see what they are reading, learning, sharing and discussing.",
      TITLE_TWO: 'Suggested for You',
      DESCRIPTION_TWO:
        "We've automatically added some people to your network. Keep building your network with our suggestions.",
    },
    TOPICS: {
      HEADER: {
        TITLE: 'Follow Your Topic',
        SUBTITLE: '',
      },
      NAVIGATION: {
        PREVIOUS_LABEL: 'Build Your Network',
        NEXT_LABEL: 'Become a Member',
      },
      TITLE: 'All Topics',
      SUBTITLE:
        'Your website experience will be customized based on the topics you select. Some topics have been added automatically based on your information, but you can edit your topics at any time.',
    },
    MEMBERSHIP: {
      AUTO_RENEW: {
        AUTO_RENEW_THANKS: 'Thank you for signing up for auto-renewal!',
        AUTO_RENEW_NOTE: `Your payment information has been securely stored and will be used when your order is renewed for its next cycle. Your order will automatically renew annually and the payment card you provide today will be charged at the time of the renewal. Upon your next auto-renewal payment and annually thereafter so long as you’re opted in, a 10% discount will be applied to your ATD national membership or your joint ATD chapter and national membership purchase. Please note that you cannot auto-renew chapter-only memberships on td.org.`,
        AUTO_RENEW_OFF_ONE: `If you decide to opt-out of auto-renewal, you can cancel 
        the automatic renewal at anytime within thirty-one (31) 
        days prior to the renewal of the order and payment. 
        To do so, toggle the auto-renew off.`,
        AUTO_RENEW_OFF_TWO: `If you wish to cancel auto-renewal, you can do so at any time. To do so, toggle the auto-renew off.`,
      },
      HEADER: {
        TITLE: 'Become A Member',
        SUBTITLE: '',
      },
      NAVIGATION: {
        PREVIOUS_LABEL: 'Follow Topics',
        NEXT_LABEL: 'No thanks, finish now',
      },
      TITLE: 'Put the Power of Membership to Work for You',
      SUBTITLE:
        "With ATD membership, you'll get access to practical tools and templates, best practices from leading experts, insights into trends and tech, and a global community of peers to enhance and expand your network.",
      PROFESSIONAL: {
        TITLE: 'Professional',
        PRICE: '$279',
        PRICE_LABEL: 'Per Year',
        BUTTON_ONE: 'Join Now',
        BUTTON_TWO: 'Learn More',
        TEXT: 'and more.',
        FEATURE_TEXT: `With ATD Professional membership, you'll receive annual access to:`,
        FEATURES: {
          FEATURE_MAG: 'TD Magazine Subscription',
          FEATURE_LIB: 'Templates and Tools Library',
          FEATURE_GUIDE: 'TD at Work Guides',
        },
        RATE_TITLE: 'Professional Membership Special Rates',
        RATES: {
          STUDENTS: {
            TITLE: 'Students',
            PRICE: '$119',
            PRICE_LABEL: 'Per Year',
            BUTTON: 'Select',
          },
          SENIOR: {
            TITLE: 'Seniors (65+)',
            PRICE: '$139',
            PRICE_LABEL: 'Per Year',
            BUTTON: 'Select',
          },
          YOUNG_PRO: {
            TITLE: 'Young Professionals',
            PRICE: '$249',
            PRICE_LABEL: 'Per Year',
            BUTTON: 'Select',
          },
        },
      },
      PROFESSIONAL_PLUS: {
        TITLE: 'Professional Plus',
        POPULAR: 'Most Popular',
        PRICE: '$439',
        PRICE_LABEL: 'Per Year',
        BUTTON_ONE: 'Join Now',
        BUTTON_TWO: 'Learn More',
        TEXT: 'and more.',
        FEATURE_TEXT: `With ATD Professional Plus membership, you'll receive annual access to:`,
        FEATURES: {
          FEATURE_MAG_PLUS: 'TD Magazine Subscription',
          FEATURE_LIB_PLUS: 'Templates and Tools Library',
          FEATURE_GUIDE_PLUS: 'TD at Work Guides',
          FEATURE_MICRO_PLUS: '99 Micro Course Library',
        },
        RATE_TITLE: 'Professional Plus Membership Special Rates',
        RATES: {
          STUDENTS: {
            TITLE: 'Students',
            PRICE: '$279',
            PRICE_LABEL: 'Per Year',
            BUTTON: 'Select',
          },
          SENIORS: {
            TITLE: 'Seniors (65+)',
            PRICE: '$299',
            PRICE_LABEL: 'Per Year',
            BUTTON: 'Select',
          },
          YOUNG_PRO: {
            TITLE: 'Young Professionals',
            PRICE: '$409',
            PRICE_LABEL: 'Per Year',
            BUTTON: 'Select',
          },
        },
      },
      ENTERPRISE: {
        TITLE: 'ATD Enterprise',
        SUBTITLE: 'On a team of five or more?',
        DESCRIPTION:
          'Provide your team with the talent development resources and opportunities they need to be successful yearlong.',
        BUTTON_ONE: 'Learn More',
      },
      MODAL: {
        STUDENTS: {
          TITLE: 'Student Membership',
          DESCRIPTION:
            'I qualify to meet the student professional membership eligibility:',
          BULLETS: {
            ST_ONE: {
              BULLET:
                'Enrolled in a minimum of 12 credit hours per year (or the equivalent of at least half-time) at an accredited university or college',
            },
            ST_TWO: {
              BULLET: 'OR an active ATD chapter student member',
            },
          },
        },
        SENIORS: {
          TITLE: 'Senior Membership',
          DESCRIPTION:
            'I qualify to meet the senior professional membership eligibility:',
          BULLETS: {
            SE_ONE: {
              BULLET: 'At least 65 years of age',
            },
          },
        },
        YOUNG_PROFESSIONAL: {
          TITLE: 'Young Professional Membership',
          DESCRIPTION:
            'I qualify to meet the young professional professional membership eligibility:',
          BULLETS: {
            YP_ONE: {
              BULLET: '35 years of age or younger',
            },
          },
        },
      },
    },
  },
  DASHBOARD: {
    WELCOME_MODAL: {
      TITLE: 'Welcome to MyATD!',
      CTA: 'Explore Now',
      DESCRIPTION: `We've redesigned our experience to better serve content that is relevant to you and your interests. Follow topics, add people to your network, access your past and upcoming activity, and more!`,
    },
    RECENT_LEARNING_ACTIVITY: 'Recent Learning Activity',
    MY_LEARNING: 'My Learning',
    MY_ACTIVITY: 'My Activity',
    MY_MEMBERSHIP: 'My Membership',
    VIEW_ALL_NETWORK_ACTIVITY: 'View All Network Activity',
    SEE_ALL_CONTRIBUTIONS: 'See all Contributions',
    SEE_MY_BENEFITS: 'See my benefits',
    VIEW_ALL_TOPICS: 'View All Topics',
    MY_BOOKMARKS: 'My Bookmarks',
    EFFECTIVE: 'EFFECTIVE',
    VISIT_BENEFITS_CENTER: 'Visit the Benefits Center',
    MEMBERSHIP_WIDGET_TITLE: 'Become a Member Today!',
    MEMBERSHIP_WIDGET_BUTTON: 'Join Now',
    MEMBERSHIP_WIDGET_SUB: `Unlock access to <i> TD at Work </i> guides, templates and tools, and thousands of videos, webinars, magazine articles, and more.`,
    MEMBERSHIP_WIDGET_DESCRIPTION:
      'Take advantage of all the member-only tools and resources to make the most of your membership.',
    CAPABILITY: {
      LOADING: 'Loading...',
      ERROR: 'Error!',
      TD_CAPABILITY_MODEL: 'TD Capability Model',
      VIEW_CAPABILITY_MODEL: 'View Capability Model',
      LABELS: {
        BEGIN_ASSESSMENT: 'Begin the Assessment',
        FINISH_ASSESSMENT: 'Finish Assessment',
        REASSESS: 'Reassess',
        BROWSE_PATHS: 'Browse Learning Paths',
        GET_STARTED: 'Get Started',
        VIEW_ALL: 'View All',
        EXPLORE_PATHS: 'Explore Learning Paths',
      },
      CONTENT: {
        TAKE_ASSESSMENT: 'Take the Self-Assessment',
        TAKE_ASSESSMENT_DESCRIPTION:
          'Start by measuring your skills and knowledge.',
        FINISH_ASSESSMENT: 'Finish the Self-Assessment',
        COMPLETE_ASSESSMENT: 'Self-Assessment',
        PERSONAL: 'Building Personal Capability',
        PROFESSIONAL: 'Developing Professional Capability',
        ORGANIZATIONAL: 'Impacting Organizational Capability',
        CHOOSE_PATH: 'Choose a Learning Path',
        CHOOSE_PATH_DESCRIPTION:
          'Choose a learning path to help you develop in your career.',
        CONGRATULATIONS: 'Congratulations',
        CONGRATULATIONS_DESCRIPTION:
          'You completed all the activities in your learning plan. You can either add more activities, or choose a new path.',
        LEARNING_PATH: 'My Learning Path',
        LEARNING_PATH_DESCRIPTION: 'General Improvement - Self Guided',
        LEARNING_PLAN: 'My Learning Plan',
        LEARNING_PLAN_DESCRIPTION: 'You have not created a learning plan.',
        ACTIVITIES_COMPLETED: 'Activities Completed',
      },
    },
    RECOMMENDED: {
      TITLE: 'Recommended For You',
      EMPTY_STATE_TITLE: 'You are not following any topics!',
      EMPTY_STATE_DESCRIPTION:
        'Add one or more ATD topics to begin seeing content tailored for your preferences.',
      ADD: 'Add',
    },
    LEARNING_ACTIVITY: {
      NO_ACTIVITY_MESSAGE: `You don't have any activity to display. Here are some ways to get started`,
      TAKE_A_COURSE: 'Take a Course',
    },
  },
  BENEFITS: {
    BENEFITS_CENTER: {
      STATE_OF_INDUSTRY: {
        DESCRIPTION: `The <i> State of the Industry </i> report is ATD's annual review of talent development trends, spending, and activities that can help benchmark your team’s work.`,
      },
      CASE_STUDY: {
        DESCRIPTION: `A curated collection of proven talent development case study solutions from top-performing organizations around the world.`,
      },
      TD_MAGAZINE: {
        DESCRIPTION: `Full digital issues of our monthly award-winning magazine delivering the best of the industry's top trends and topics.`,
      },
      WHITE_PAPERS: {
        DESCRIPTION: `These data-driven reports highlight insights and key findings from our full research reports at a glance.`,
      },
      NEWS_LETTER: {
        DESCRIPTION: `Stay on top of the latest news and trends in talent development curated specifically for our members.`,
      },
      TD_MAG_ARTICLES: {
        DESCRIPTION: `Articles from our monthly award-winning magazine that examines industry trends and topics.`,
      },
      WEBINAR: {
        DESCRIPTION: `The full archive of webinar recordings available for members to quickly build skills and competencies on critical topics.`,
      }
    },
    MEMBER_BENEFITS: {
      VIDEO: {
        DESCRIPTION: `A repository of all ATD's video content, including hundreds of hours of resources for members.`,
      },
      TD_AT_WORK: {
        DESCRIPTION:
          'These 20-page guides dive deeply into a crucial talent development topic with actionable tools at the end to use right away.',
      },
      TEMPLATES_AND_TOOLS: {
        DESCRIPTION: `Don't reinvent the wheel! Use our quick, actionable templates and tools to get the job done. Don’t see what you need? Fill out this short <a target="_blank" href="%s">form</a> to let us know what additional resources we can provide.`,
      },
      COURSES_WITH_ACCESS: {
        TITLE:
          'With Professional Plus membership, you have access to our library of 99 adaptive micro courses.',
        DESCRIPTION:
          'These courses assess your knowledge base and tailor content in real-time to your unique learning needs.',
      },
      MICRO_COURSE_LIB_NO_ACCESS: {
        TITLE:
          'It appears your membership does not include access to our micro course library.',
        DESCRIPTION:
          'Professional Plus membership for individuals and Bronze, Silver, and Gold membership for enterprise customers are able to dive deeper into their professional development by accessing this library of 99 micro courses. With access to this library, you can assess your knowledge base and view content tailored to your unique learning needs in real-time. Reach out to Customer Care to learn more about how to gain access to the library today.',
      },
    },
    COMMUNITY: {
      SUBTITLE_ONE:
        'Now more than ever, community and connection are critical. Your ATD membership connects you to a global network of like-minded peers.',
      SUBTITLE_TWO:
        'Contribute to and engage with our vibrant professional community today!',
    },
  },
  ACTIVITY_HISTORY: {
    MENU: {
      LEARNING: 'Learning',
      BOOKMARKS: 'Bookmarks',
      NETWORK: 'Network Activity',
      CONTRIBUTIONS: 'Contributions',
    },
    TABLE: {
      TITLE: 'Title',
      RESOURCES: 'Resources',
      STATUS: 'Status',
      DETAILS: 'Details',
      EARNED: 'Earned',
      ACTIONS: 'Actions',
      VIEW_BADGE: 'View Badge',
      DOWNLOAD_CERTIFICATE: 'Download Certificate',
      LAUNCH: 'Launch',
      VIEW: 'View',
      ASC_LABEL_A_Z: 'A to Z',
      DESC_LABEL_A_Z: 'Z to A',
    },
    BOOKMARKS: {
      NO_RESULTS_MESSAGE: 'It appears you haven’t saved any bookmarks yet.',
      NO_RESULTS_DESCRIPTION:
        'Remember that as you explore this site you can bookmark any content you want to reference later.',
    },
    CERTIFICATIONS: {
      TITLE: 'Certifications',
      INTERESTED: 'Interested in a certification?',
      WE_OFFER:
        'We offer professional certifications in talent development. Learn more about our programs and determine your eligibility.',
      SEE_CERT: 'See Certifications',
      ALREADY_WORKING: 'Already working on your certification?',
      START_APPLICATION: 'Start your application or schedule your exam.',
      VIEW_PORTAL: 'Visit the Certification Portal',
    },
    CONFERENCES: {
      TITLE: 'Conferences',
      NO_RESULTS_MESSAGE: `You're not registered for any conferences.`,
      NO_RESULTS_DESCRIPTION:
        'Explore upcoming events to find one that meets your needs.',
      SEE_CONFERENCES: 'See Conferences',
    },
    COURSES: {
      TITLE: 'Courses',
      NO_RESULTS_MESSAGE: `You have not yet enrolled in any ATD courses.`,
      NO_RESULTS_DESCRIPTION:
        'Lifelong learning is a career differentiator. When you’re ready, browse the complete list of ATD courses. There are live online, on-demand, and face-to-face options to fit your needs.',
      SEE_COURSES: 'See Courses',
      HELP: 'For questions about the status of your course, contact',
      CUSTOMER_CARE: 'Customer Care',
      NO_RESULTS_FILTER: 'We could not find any results for the criteria you entered.' 
    },
    NETWORK: {
      NO_RESULTS_MESSAGE: `You don't have any connections yet.`,
    },
    USER_CONTENT: {
      NO_RESULTS_MESSAGE: `We want to hear from you!`,
      NO_RESULTS_DESCRIPTION:
        'Consider writing a blog or starting a discussion to contribute toand engage with this vibrant professional community.',
    },
    WEBINARS: {
      NO_RESULTS_MESSAGE: `You don't have any past or upcoming webinars.`,
      NO_RESULTS_DESCRIPTION:
        'Explore the hundreds of webinars available to view on-demand or register for an upcoming one.',
    },
  },
  FORMS: {
    COMMUNICATION: {},
  },
  CARD: {
    VIEW_ALL: 'View all',
  },
  ENUM: {
    CERTIFICATION_TYPE: {
      APTD: 'Associate Professional in Talent Development (APTD)',
      CPTD: 'Certified Professional in Talent Development (APTD)',
    },
  },
}

export default strings
