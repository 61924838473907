import CardMedia from "@mui/material/CardMedia";
import ErrorPage from "../../Error";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@material-ui/core/styles";
import ShareIcon from "@mui/icons-material/Share";
import ShareIcons from "../../ShareIcons";
import { useState } from "react";
import Avatar from "@material-ui/core/Avatar";
import { imgResize } from "../../../Helpers/imgix";

const useStyles = makeStyles(() => ({
  showOptions: {
    height: "30px",
    width: "30px",
    padding: "2px",
    backgroundColor: "rgba(244, 244, 244)",
    marginTop: "5px",
    marginLeft: "-40px",
    cursor: "pointer",
    color: "#737373",
  },
  blogGrid: {
    display: "flex",
    flexDirection: "row",
    marginBottom: "-10px",
  },
  image: {
    width: "100%",
    height: "auto",
    objectFit: "cover",
  },
}));

const Blog = ({ data }) => {
  const classes = useStyles();
  const [showIcons, setShowIcons] = useState(false);
  return (
    <Grid>
      {data !== undefined ? (
        <Grid>
          <Grid className={classes.blogGrid}>
            <CardMedia
              component="img"
              image={
                data.imageUrl
                  ? imgResize(data.imageUrl, { w: 500, h: 270 })
                  : "/article.png"
              }
              alt={data.title}
              className={classes.image}
            />
            <Avatar className={classes.showOptions}>
              <ShareIcon
                fontSize="small"
                onClick={() => setShowIcons(!showIcons)}
              />
            </Avatar>
          </Grid>
          {showIcons ? (
            <ShareIcons dataShare={data} clickedFunc={setShowIcons} />
          ) : null}
        </Grid>
      ) : (
        <ErrorPage />
      )}
    </Grid>
  );
};

export default Blog;
