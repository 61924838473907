import ReactPlayerLoader from "@brightcove/react-player-loader";
import ErrorPage from "../../Error";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  topStoryVideo: {
    minWidth: "280px",
  },
}));
const Video = ({ data }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.topStoryVideo}>
      {data.brightcoveId !== null ? (
        <ReactPlayerLoader
          accountId={process.env.REACT_APP_BRIGHTCOVE_ACCOUTN_ID}
          videoId={data.brightcoveId}
          playerId={process.env.REACT_APP_BRIGHTCOVE_PLAYER_ID}
          options={{
            playsinline: true,
            autoplay: false,
            muted: false,
          }}
          embedOptions={{ responsive: true }}
        />
      ) : (
        <ErrorPage />
      )}
    </Grid>
  );
};

export default Video;

// YouTube Alternative
// const video = (res) => {
//     return (
//         <CardMedia
//             height='190'
//             component="iframe"
//             title={res.title}
//             image={`https://f1.media.brightcove.com/4/4684385851001/4684385851001_5702229210001_5702205355001.mp4?pubId=4684385851001&videoId=5702205355001`}
//             className={classes.mediaSizeNextConferenceImage}
//         />
//         )
//     }
