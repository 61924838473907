import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { CardActions } from "@mui/material";
import turnicate from "../../Helpers/truncateHelper";
import useWindowSize from "../../hooks/useResize";
import ShareIcons from "../ShareIcons";
import ShareIcon from "@mui/icons-material/Share";
import { useState } from "react";
import segmentEventClick from "../../Helpers/segmentEventClick";
import { imgResize } from "../../Helpers/imgix";

const useStyles = makeStyles((theme) => ({
  gridFlex: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: "5px",
  },
  cardContentFlex: {
    cursor: "pointer",
    "&.MuiCardContent-root": {
      padding: 0,
    },
  },
  title: {
    fontWeight: "bold",
    paddingLeft: "10px",
    paddingRight: "10px",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px",
    },
  },
  description: {
    fontSize: "12px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  cardMediaFlexSm: {
    height: "70px",
    width: "70px",
  },
  cardMediaFlexMd: {
    height: "140px",
    width: "140px",
  },
  feedCardFlex: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: "4px",
    width: "100%",
    minWidth: "280px",
    maxHeight: "120px",
    minHeight: "120px",
    [theme.breakpoints.up("sm")]: {
      maxHeight: "185px",
      minHeight: "185px",
    },
  },
  share: {
    backgroundColor: "rgb(239,239,239)",
    display: "flex",
    justifyContent: "space-between",
  },
  sponsoredText: {
    fontSize: "13px",
    fontWeight: "bold",
    color: "#737373",
  },
  clickShare: {
    cursor: "pointer",
    color: "#737373",
  },
}));

const FeedCardFlex = ({ data, drawerOpen, queryId, indexPosition }) => {
  const classes = useStyles();
  const windowWidth = useWindowSize();
  const [showIcons, setShowIcons] = useState(false);

  return (
    <Grid
      item
      xs={drawerOpen ? 12 : 12}
      sx={{ padding: "10px" }}
      key={data.objectID}
    >
      <Card className={classes.feedCardFlex}>
        <Grid className={classes.gridFlex}>
          <CardMedia
            component="img"
            image={
              data.imageUrl
                ? imgResize(data.imageUrl, { w: 200, h: 200 })
                : "/article.png"
            }
            alt={data.title}
            className={
              windowWidth > 600
                ? classes.cardMediaFlexMd
                : classes.cardMediaFlexSm
            }
          />
          <CardContent
            className={classes.cardContentFlex}
            onClick={() =>
              segmentEventClick(data, drawerOpen, queryId, indexPosition, false)
            }
          >
            <Typography
              gutterBottom
              variant="p"
              component="p"
              className={classes.title}
            >
              {turnicate(data.title, 10, "title")}
            </Typography>

            <Typography className={classes.description} color="text.secondary">
              {windowWidth > 600
                ? turnicate(data.description, 255, "desc", drawerOpen)
                : null}
            </Typography>
          </CardContent>
        </Grid>
        <CardActions className={classes.share}>
          <Typography className={classes.sponsoredText}>
            {data.sponsored ? "Sponsored" : ""}
          </Typography>
          <ShareIcon
            className={classes.clickShare}
            fontSize="small"
            onClick={() => setShowIcons(!showIcons)}
          />
        </CardActions>
      </Card>
      {showIcons ? (
        <ShareIcons dataShare={data} clickedFunc={setShowIcons} />
      ) : null}
    </Grid>
  );
};

export default FeedCardFlex;
