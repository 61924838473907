const useResizeHighlights = (results, windowWidth) => {
  let mainResults = [];
  let showMore = true;
  let column = 0;

  if (windowWidth < 800 && windowWidth >= 700) {
    column = 4;
    mainResults = results
      .map((res) => {
        return res;
      })
      .slice(0, 8);
    if (mainResults.length === results.length) {
      showMore = false;
    }
  } else if (windowWidth < 700 && windowWidth >= 510) {
    column = 3;
    mainResults = results
      .map((res) => {
        return res;
      })
      .slice(0, 6);
    if (mainResults.length === results.length) {
      showMore = false;
    }
  } else if (windowWidth < 510) {
    column = 2;
    mainResults = results
      .map((res) => {
        return res;
      })
      .slice(0, 4);
    if (mainResults.length === results.length) {
      showMore = false;
    }
  } else {
    column = 4;
    mainResults = results
      .map((res) => {
        return res;
      })
      .slice(0, 8);
    if (mainResults.length === results.length) {
      showMore = false;
    }
  }

  const modifiedResults = {
    column: column,
    mainResults: mainResults,
    showMore: showMore,
  };

  return modifiedResults;
};

export default useResizeHighlights;
