import ReactGA from "react-ga";

const segmentEventClick = async (
  metaData,
  drawerOpen,
  queryId,
  indexPosition,
  topStory
) => {
  if (metaData.url) {
    let countPosition;
    let clickSource;
    if (drawerOpen) {
      countPosition = indexPosition + 11;
    } else {
      countPosition = indexPosition + 2;
    }
    if (topStory) {
      clickSource = "conference-news-feed-top-story";
      countPosition = indexPosition;
    } else {
      clickSource = "conference-news-feed";
    }
    await window.analytics.track("Product Clicked", {
      title: metaData.title,
      objectID: metaData.objectID,
      index: "master",
      queryID: queryId,
      position: countPosition,
      source: clickSource,
    });
    await googleAnalyticsEvent(metaData);
    window.open(metaData.url, "_self");
  }
};

const googleAnalyticsEvent = (data) => {
  ReactGA.event({
    category: "Product Clicked",
    action: `User clicked ${data.title} - ${data.objectID}`,
  });
};

export default segmentEventClick;
