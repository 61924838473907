const truncateHelper = (text, length, type, drawerOpen) => {
  if (text !== null) {
    if (text.length > length) {
      const result =
        type === "title"
          ? turnicateTitles(text, length)
          : truncateDesc(text, length, drawerOpen);
      return result;
    } else {
      return text;
    }
  }
};

const truncateDesc = (text, length, drawerOpen) => {
  if (drawerOpen === true) {
    return text.substring(0, 65) + "...";
  }
  if (text.length <= length) {
    return text;
  } else {
    return text.substring(0, length) + "...";
  }
};

const turnicateTitles = (text, length) => {
  const words = text.split(/\s+/).length;
  return words > length
    ? text.split(/\s+/).slice(0, length).join(" ") + "..."
    : text;
};

export default truncateHelper;
