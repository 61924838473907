import * as React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import { useContext, useState } from 'react'
import TemporaryOpenHighlightFromDrawer from './TemporaryOpenHighlightFromDrawer'
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt'
import { CheckHighlightFromDrawerContext } from '../../hooks/useContext'
import CloseIcon from '@mui/icons-material/Close'
import { makeStyles } from '@material-ui/core/styles'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import { imgResize } from '../../Helpers/imgix'

const useStyles = makeStyles((theme) => ({
  highlightHeader: {
    display: 'flex',
    flexDirection: 'column',
  },
  topHighlightsDesc: {
    [theme.breakpoints.down('sm')]: {
      fontSize: '12px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '14px',
    },
  },
  topHighlights: {
    fontWeight: 900,
    [theme.breakpoints.down('sm')]: {
      fontSize: '28px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '34px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '36px',
    },
  },
  tempHighDraw: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: '30px',
    paddingBottom: '30px',
    paddingLeft: '10px',
    paddingRight: '10px',
    color: 'white',
  },
  viewMoreButI: {
    '&.MuiButtonBase-root': {
      marginLeft: 'auto',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center',
      textTransform: 'none',
    },
    '&.MuiButtonBase-root:hover': {
      backgroundColor: 'transparent',
    },
  },
}))

const TemporaryHighlightDrawer = (drawerResults) => {
  const { openHighlightFromDrawer, setOpenHighlightFromDrawer } = useContext(
    CheckHighlightFromDrawerContext
  )
  const [openClickedHighlightFromDrawer, setOpenClickedHighlightFromDrawer] =
    useState()
  const [state, setState] = useState({
    right: false,
  })
  const classes = useStyles()

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === 'keydown' &&
      (event.key === 'Tab' || event.key === 'Shift')
    ) {
      return
    }

    setState({ ...state, [anchor]: open })
  }

  const filteredResults = drawerResults.allResults.results.filter((allRes) => {
    return drawerResults.previousResults.indexOf(allRes) === -1
  })

  const list = (anchor) => (
    <Box sx={{ width: 'auto' }} role="presentation">
      <Grid className={classes.tempHighDraw}>
        <div className={classes.highlightHeader}>
          <span className={classes.topHighlights}> ATD24 Photos </span>
          {/* <span className={classes.topHighlightsDesc}>
            {" "}
            Check out highlights from our conference
          </span> */}
        </div>
        <Button
          onClick={toggleDrawer(anchor, false)}
          sx={{
            '&.MuiButtonBase-root:hover': {
              backgroundColor: 'transparent',
            },
          }}
        >
          <CloseIcon sx={{ fontSize: '24px', color: 'white' }} />
        </Button>
      </Grid>
      <Grid container sx={{ display: 'flex', justifyContent: 'center' }}>
        <ImageList cols={2} sx={{ paddingLeft: '10px', paddingRight: '10px' }}>
          {filteredResults.map((item, index) => (
            <ImageListItem key={index} style={{ margin: '10px' }}>
              <img
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setOpenHighlightFromDrawer(true)
                  setOpenClickedHighlightFromDrawer(item)
                }}
                src={`${imgResize(item)}?w=500&h=500&fit=crop&auto=format`}
                srcSet={`${imgResize(
                  item
                )}?w=500&h=500&fit=crop&auto=format&dpr=2 2x`}
                alt="conference-snapshot"
                loading="eager"
                rel="preload"
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Grid>
      {openHighlightFromDrawer === true ? (
        <TemporaryOpenHighlightFromDrawer
          clickedHighlightFromDrawer={openClickedHighlightFromDrawer}
        />
      ) : null}
    </Box>
  )

  return (
    <Grid>
      <Button
        onClick={toggleDrawer('right', true)}
        className={classes.viewMoreButI}
      >
        <Typography
          color="text.secondary"
          sx={{
            fontSize: '12px',
          }}
        >
          More Images
        </Typography>
        <ArrowRightAltIcon
          sx={{ fontSize: '22px', color: 'rgba(0, 0, 0, 0.6)' }}
        />
      </Button>
      <Drawer
        anchor="right"
        open={state['right']}
        onClose={toggleDrawer('right', false)}
      >
        {list('right')}
      </Drawer>
    </Grid>
  )
}

export default TemporaryHighlightDrawer
