import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { CardActions } from "@mui/material";
import turnicate from "../../Helpers/truncateHelper";
import useWindowSize from "../../hooks/useResize";
import ShareIcons from "../ShareIcons";
import ShareIcon from "@mui/icons-material/Share";
import { useState } from "react";
import segmentEventClick from "../../Helpers/segmentEventClick";
import { imgResize } from "../../Helpers/imgix";

const useStyles = makeStyles((theme) => ({
  title: {
    fontWeight: "bold",
    [theme.breakpoints.down("sm")]: {
      fontSize: "14px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "16px",
    },
  },
  description: {
    fontSize: "12px",
  },
  feedCard: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    borderRadius: "4px",
    width: "100%",
    minWidth: "280px",

    [theme.breakpoints.up("sm")]: {
      height: "auto",
    },
  },
  share: {
    backgroundColor: "rgb(239,239,239)",
    display: "flex",
    justifyContent: "space-between",
  },
  sponsoredText: {
    fontSize: "13px",
    fontWeight: "bold",
    color: "#737373",
  },
  clickShare: {
    cursor: "pointer",
    color: "#737373",
  },
  image: {
    width: "100%",
    height: "auto",
    objectFit: "fill",
  },
}));

const FeedCard = ({ data, drawerOpen, queryId, indexPosition }) => {
  const classes = useStyles();
  const windowWidth = useWindowSize();
  const [showIcons, setShowIcons] = useState(false);

  return (
    <Grid
      item
      xs={drawerOpen ? 12 : 12}
      sx={{ padding: "10px" }}
      key={data.objectID}
    >
      <Card className={classes.feedCard}>
        <Grid>
          <CardMedia
            component="img"
            image={
              data.imageUrl
                ? imgResize(data.imageUrl, { w: 500, h: 270 })
                : "/article.png"
            }
            alt={data.title}
            className={classes.image}
          />
        </Grid>
        <CardContent
          sx={{ cursor: "pointer" }}
          onClick={() =>
            segmentEventClick(data, drawerOpen, queryId, indexPosition, false)
          }
        >
          <Typography
            gutterBottom
            variant="p"
            component="p"
            className={classes.title}
          >
            {turnicate(data.title, 10, "title")}
          </Typography>

          <Typography className={classes.description} color="text.secondary">
            {windowWidth > 750
              ? turnicate(data.description, 220, "desc", drawerOpen)
              : null}
          </Typography>
        </CardContent>
        <CardActions className={classes.share}>
          <Typography className={classes.sponsoredText}>
            {data.sponsored ? "Sponsored" : ""}
          </Typography>
          <ShareIcon
            className={classes.clickShare}
            fontSize="small"
            onClick={() => setShowIcons(!showIcons)}
          />
        </CardActions>
      </Card>
      {showIcons ? (
        <ShareIcons dataShare={data} clickedFunc={setShowIcons} />
      ) : null}
    </Grid>
  );
};

export default FeedCard;
