import SkeletonLoader from "@bit/atd.web.loaders.skeleton";
import Box from "@mui/material/Box";

const ComponentLoader = () => {
  return (
    <Box sx={{ paddingTop: 5, width: "100%" }}>
      <SkeletonLoader />
      <br />
      <br />
    </Box>
  );
};

export default ComponentLoader;
