// @react
import React from 'react'
// @libraries
import { makeStyles } from '@material-ui/core/styles'
// @components
import Grid from '@bit/atd.web.grid'
import GroupTitle from '@bit/atd.web.header-group-title'
import Link from '@bit/atd.web.header-link'
// @constants
import { RouteNamesExternal } from '../../../constants/routeNamesExternal'

const useStyles = makeStyles({
  linkColumn: {
    marginRight: '41px',
  },
  linksWrap: {
    marginBottom: '2em',
  },
  linkWrap: {
    marginBottom: '16px',
  },
})

const DesktopLinks = () => {
  /********************************************
   *  HOOKS
   *********************************************/

  const classes = useStyles()

  /********************************************
   *  RENDER
   *********************************************/

  return (
    <Grid container wrap="nowrap">
      <Grid item className={classes.linkColumn}>
        <GroupTitle>About</GroupTitle>
        <ul>
          <li className={classes.linkWrap}>
            <Link href={RouteNamesExternal.ATD_ABOUT_US}>About Us</Link>
          </li>
          <li className={classes.linkWrap}>
            <Link href={RouteNamesExternal.HELP_FAQ}>FAQs</Link>
          </li>
          <li className={classes.linkWrap}>
            <Link href={RouteNamesExternal.ATD_ENTERPRISE_HOME}>
              Enterprise Solutions
            </Link>
          </li>
          <li className={classes.linkWrap}>
            <Link href={RouteNamesExternal.ATD_CHAPTERS}>Chapters</Link>
          </li>
          <li className={classes.linkWrap}>
            <Link href={RouteNamesExternal.ATD_GLOBAL_HOME}>ATD Global</Link>
          </li>
          <li className={classes.linkWrap}>
            <Link href={RouteNamesExternal.ATD_PRESS_ROOM}>ATD Press Room</Link>
          </li>
          <li className={classes.linkWrap}>
            <Link href={RouteNamesExternal.ATD_STORE}>Store</Link>
          </li>
          <li className={classes.linkWrap}>
            <Link href={RouteNamesExternal.ATD_ADVERTISE}>
              Advertise With Us
            </Link>
          </li>
        </ul>
      </Grid>
      <Grid item className={classes.linkColumn}>
        <GroupTitle>Careers</GroupTitle>
        <ul>
          <li className={classes.linkWrap}>
            <Link href={RouteNamesExternal.ATD_CAREERS}>Careers at ATD</Link>
          </li>
          <li className={classes.linkWrap}>
            <Link href={RouteNamesExternal.JOBS_BANK}>ATD Job Bank</Link>
          </li>
        </ul>
      </Grid>
      <Grid item className={classes.linkColumn}>
        <div className={classes.linksWrap}>
          <GroupTitle>Email Us</GroupTitle>
          <ul>
            <li className={classes.linkWrap}>
              <Link href={RouteNamesExternal.ATD_MAIL_CUSTOMER_CARE}>
                customercare@td.org
              </Link>
            </li>
          </ul>
        </div>
        <div className={classes.linksWrap}>
          <GroupTitle>Call Us</GroupTitle>
          <ul>
            <li className={classes.linkWrap}>
              <Link href={RouteNamesExternal.ATD_PHONE_GLOBAL}>
                +1.800.628.2783
              </Link>
            </li>
            <li className={classes.linkWrap}>
              <Link href={RouteNamesExternal.ATD_PHONE_US}>
                +1.703.683.8100
              </Link>
            </li>
          </ul>
        </div>
      </Grid>
    </Grid>
  )
}

export default DesktopLinks
