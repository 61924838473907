import { makeStyles } from '@material-ui/core/styles'
import Carousel from 'react-multi-carousel'
import useResize from '../../hooks/useResize'
import 'react-multi-carousel/lib/styles.css'
import { Grid } from '@material-ui/core'
import ErrorPage from '../Error'

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 3,
    slidesToSlide: 1, // optional, default to 1.
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 2,
    slidesToSlide: 1, // optional, default to 1.
  },
}
const useStyles = makeStyles((theme) => ({
  sponsorText: {
    fontWeight: 'bold',
    letterSpacing: '2px',
    paddingBottom: '10px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
    },
  },
  carousel: {
    minWidth: '300px',
    maxHeight: '300px',
    backgroundColor: 'white',
    height: 'auto',
  },
  slide: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'white',
  },
  desktop: {
    width: '200px',
    padding: '10px',
  },
  mobileSx: {
    width: '140px',
    padding: '10px',
  },
}))

const SponsorCarousel = ({ data }) => {
  const logos = data.length
  const classes = useStyles(logos)
  const windowWidth = useResize()

  const shuffleArray = (array) => {
    for (var i = array.length - 1; i > 0; i--) {
      var j = Math.floor(Math.random() * (i + 1))
      var temp = array[i]
      array[i] = array[j]
      array[j] = temp
    }
  }

  shuffleArray(data)
  return (
    <Grid>
      {data === undefined ? (
        <ErrorPage />
      ) : (
        <>
          <div className={classes.sponsorText}> ATD24 Platinum Sponsors </div>
          <Carousel
            className={classes.carousel}
            responsive={responsive}
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={3000}
            arrows={false}
            customTransition={'transform 300ms ease-in-out'}
          >
            {data.map((logo) => (
              <div className={classes.slide} key={logo}>
                <img
                  src={logo}
                  className={
                    windowWidth > 700
                      ? classes.desktop
                      : windowWidth < 700 && windowWidth > 375
                      ? classes.mobileSx
                      : classes.mobileSx
                  }
                  alt=""
                />
              </div>
            ))}
          </Carousel>
        </>
      )}
    </Grid>
  )
}

export default SponsorCarousel
