import { Grid } from '@mui/material'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles((theme) => ({
  mediaDate: {
    color: 'rgba(155, 155, 155, 1)',
    fontWeight: 'bold',
    letterSpacing: '0.05em',
    paddingLeft: '2px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '20px',
    },
  },
  mediaHeader: {
    fontSize: '24px',
    fontStyle: 'normal',
    fontWeight: '700',
    lineHeight: '32px',
    letterSpacing: '0.015em',
    textAlign: 'left',
    paddingBottom: '4px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '28px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '34px',
    },
    [theme.breakpoints.up('lg')]: {
      fontSize: '36px',
    },
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
  },

  image: {
    width: '70px',
  },
}))

const Header = () => {
  const classes = useStyles()
  const dateNow = new Date()
  // Formate date to mm/dd/yyyy format
  const formatMonth = dateNow.toLocaleString('default', { month: 'long' })
  const formatDay = dateNow.getDate()
  const date = `${formatMonth} ${formatDay}`
  return (
    <Grid
      sx={{
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <div className={classes.container}>
        <span className={classes.mediaHeader}>Conference Daily</span>
        <span className={classes.mediaDate}>{date}</span>
      </div>
      <Grid>
        <img
          src="/ATD-24-News-Circle.png"
          alt="atd-small-logo"
          className={classes.image}
        />
      </Grid>
    </Grid>
  )
}

export default Header
