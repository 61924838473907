import { useEffect, useState } from "react";

const useWindowSize = () => {
  const [size, setSize] = useState(window.innerWidth);
  useEffect(() => {
    const updateSize = () => {
      setSize(window.innerWidth);
    };
    window.addEventListener("resize", updateSize);
    updateSize();
    return () => window.removeEventListener("resize", updateSize);
  });
  return size;
};

export default useWindowSize;
