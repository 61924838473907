import { makeStyles } from "@material-ui/core/styles";
import FacebookIcon from "@material-ui/icons/Facebook";
import TwitterIcon from "@material-ui/icons/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
import Typography from "@mui/material/Typography";
import shareButtons from "../../Helpers/shareButtons";
import LinkIcon from "@mui/icons-material/Link";
import Card from "@mui/material/Card";
import CloseIcon from "@mui/icons-material/Close";
import Grid from "@mui/material/Grid";
import { useState, useEffect } from "react";

const useStyles = makeStyles((theme) => ({
  shareTextTitle: {
    color: "rgb(239,239,239)",
    fontSize: "16px",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      fontSize: "18px",
    },
  },
  shareButtonsContainer: {
    display: "flex",
    justifyContent: "space-around",
    paddingTop: "15px",
  },
  hidden: {
    display: "none",
  },
  facebook: {
    color: "#3b5998",
    width: "40px",
    height: "40px",
    margin: "0 5px 0",
    "&:hover": {
      color: "#faa92f",
      cursor: "pointer",
    },
  },
  twitter: {
    color: "#1da1f2",
    margin: "0 5px 0",
    width: "40px",
    height: "40px",
    "&:hover": {
      color: "#faa92f",
      cursor: "pointer",
    },
  },
  linkedin: {
    color: "#0077b5",
    margin: "0 5px 0",
    width: "40px",
    height: "40px",
    "&:hover": {
      color: "#faa92f",
      cursor: "pointer",
    },
  },
  copy: {
    color: "#8f8f8f",
    margin: "0 5px 0",
    width: "40px",
    height: "40px",
    "&:hover": {
      color: "#faa92f",
      cursor: "pointer",
    },
  },
  iconsGrid: {
    position: "absolute",
    backgroundColor: "rgb(54,55,58)",
    minWidth: "230px",
    [theme.breakpoints.up("sm")]: {
      minWidth: "330px",
    },
    minHeight: "180px",
    left: "50%",
    top: "150px",
    transform: "translateX(-50%)",
    borderRadius: "10px",
    padding: "20px",
  },
  blurBackground: {
    display: "block",
    position: "fixed",
    top: "0",
    left: "0",
    width: "100%",
    height: "100%",
    zIndex: "9999",
    backgroundColor: "rgba(0,0,0,0.5)",
    transition: "all 0.1s ease-in-out",
    overflow: "hidden",
    opacity: "1",
    pointerEvents: "auto",
  },
  shareText: {
    color: "rgb(239,239,239)",
    textAlign: "center",
  },
  linkCopied: {
    color: "rgb(239,239,239)",
    textAlign: "center",
    fontSize: "12px",
    paddingTop: "10px",
  },
  close: {
    fontSize: "22px",
    color: "rgb(239,239,239)",
    display: "flex",
    marginLeft: "auto",
    paddingBottom: "10px",
    cursor: "pointer",
  },
}));

const ShareIcons = ({ dataShare, clickedFunc }) => {
  const [success, setSuccess] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    setTimeout(() => {
      setSuccess(false);
    }, 4000);
  }, [success]);

  return (
    <Grid className={classes.blurBackground}>
      <Card className={classes.iconsGrid}>
        <div onClick={() => clickedFunc(false)}>
          <CloseIcon className={classes.close} />
        </div>
        <Typography className={classes.shareTextTitle}>
          {dataShare.title}
        </Typography>
        <hr />
        <Typography className={classes.shareText}>Share this via</Typography>
        <div className={classes.shareButtonsContainer}>
          <FacebookIcon
            className={classes.facebook}
            onClick={() => {
              shareButtons("facebook", dataShare.url);
            }}
          />
          <TwitterIcon
            className={classes.twitter}
            onClick={() => {
              shareButtons("twitter", dataShare.url);
            }}
          />
          <LinkedInIcon
            className={classes.linkedin}
            onClick={() => {
              shareButtons("linkedin", dataShare.url);
            }}
          />
          <LinkIcon
            onClick={() => {
              shareButtons("copy", dataShare.url);
              setSuccess(true);
            }}
            className={classes.copy}
          />
        </div>
        {success ? (
          <Typography className={classes.linkCopied}> Link Copied! </Typography>
        ) : null}
      </Card>
    </Grid>
  );
};

export default ShareIcons;
