const shareButton = (platform, link) => {
  const encodedLink = encodeURIComponent(link);
  const openLink = (socialLink) => {
    if (socialLink) {
      window.open(socialLink, "_blank");
    }
  };

  switch (platform) {
    case "facebook":
      link = `https://www.facebook.com/sharer/sharer.php?u=${encodedLink}`;
      openLink(link);
      break;

    case "twitter":
      link = `https://twitter.com/intent/tweet?url=${encodedLink}`;
      openLink(link);
      break;

    case "linkedin":
      link = `https://www.linkedin.com/shareArticle?mini=true&url=${encodedLink}`;
      openLink(link);
      break;

    case "copy":
      navigator.clipboard.writeText(link);
      break;

    default:
      break;
  }
};

export default shareButton;
