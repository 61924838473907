const useResizeReadings = (results, windowWidth) => {
  let showMore = true;
  let filteredTopStoryResults = [];
  let featuredResult;

  const topStoryResults = results
    .map((res) => {
      return res;
    })
    .filter((res) => res.keywords.indexOf("Conference Daily Top Story") !== -1);

  if (topStoryResults.length > 1) {
    featuredResult = topStoryResults.sort((a, b) => {
      return new Date(b.publishDate) - new Date(a.publishDate);
    })[0];

    filteredTopStoryResults = topStoryResults
      .map((res) => {
        return res;
      })
      .filter((res) => res.objectID !== featuredResult.objectID);
  } else if (topStoryResults.length === 1) {
    featuredResult = topStoryResults[0];
  } else {
    featuredResult = results.sort((a, b) => {
      return new Date(b.publishDate) - new Date(a.publishDate);
    })[0];
  }

  let mainResults = results
    .map((res) => {
      return res;
    })
    .filter((res) => res.keywords.indexOf("Conference Daily Top Story") === -1);

  if (filteredTopStoryResults.length > 0) {
    mainResults = mainResults.concat(filteredTopStoryResults).slice(0, 9);
  } else {
    mainResults = mainResults.slice(0, 9);
  }

  //main page will always have 9 pieces of content EXCLUDING top story
  if (results.length <= 9) {
    showMore = false;
  }

  const modifiedResults = {
    mainResults: mainResults,
    showMore: showMore,
    featuredResult: featuredResult,
  };

  return modifiedResults;
};

export default useResizeReadings;
