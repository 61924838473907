import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import ErrorIcon from "@mui/icons-material/Error";

const ErrorPage = () => {
  return (
    <Box
      sx={{
        p: 5,
        color: "#565559",
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
      }}
    >
      <div>
        <ErrorIcon sx={{ paddingRight: "10px" }} />
      </div>
      <div>
        <Typography
          variant="h5"
          sx={{
            fontWeight: "bold",
          }}
        >
          Network error!
        </Typography>
        <Typography
          variant="p"
          sx={{
            fontSize: "14px",
          }}
        >
          We're sorry, there was a problem retrieving information from the
          server, please refresh the page or try again later.{" "}
        </Typography>
      </div>
    </Box>
  );
};

export default ErrorPage;
