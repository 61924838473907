import { useState, useRef, useEffect } from "react";
import Drawer from "@mui/material/Drawer";
import FeedCard from "../Card";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@material-ui/core/styles";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import CloseIcon from "@mui/icons-material/Close";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import FeedCardFlex from "../CardFlex";
import FeedCardFlexReverse from "../CardFlexReverse";
import Avatar from "@material-ui/core/Avatar";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";

const useStyles = makeStyles((theme) => ({
  showOptions: {
    height: "45px",
    width: "45px",
    padding: "2px",
    backgroundColor: "black",
    cursor: "pointer",
    color: "white",
    position: "fixed",
    right: "30px",
    bottom: "50px",
    textAlign: "center",
    boxShadow: "0px 2px 8px rgb(37,37,37)",
  },
  storiesHeader: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    color: "white",
    alignItems: "center",
    paddingTop: "30px",
    paddingBottom: "30px",
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  nestedTopHeader: {
    display: "flex",
    flexDirection: "column",
  },
  topHeaderDesc: {
    [theme.breakpoints.down("sm")]: {
      fontSize: "12px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "14px",
    },
  },
  topHeader: {
    fontWeight: 900,
    [theme.breakpoints.down("sm")]: {
      fontSize: "28px",
    },
    [theme.breakpoints.up("sm")]: {
      fontSize: "34px",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "36px",
    },
  },
  viewMoreBut: {
    "&.MuiButtonBase-root": {
      marginLeft: "auto",
      cursor: "pointer",
      display: "flex",
      alignItems: "center",
      paddingTop: "13px",
      textTransform: "none",
    },
    "&.MuiButtonBase-root:hover": {
      backgroundColor: "transparent",
    },
  },
  closeBut: {
    "&.MuiButtonBase-root:hover": {
      backgroundColor: "transparent",
    },
  },
}));

const FeedDrawer = ({
  data,
  modifiedResults,
  topStoryLatest,
  open,
  handler,
  queryId,
}) => {
  const classes = useStyles();

  const postResizeResults = data.filter((res) => {
    return (
      modifiedResults.indexOf(res) === -1 &&
      topStoryLatest.objectID !== res.objectID
    );
  });

  const handleScroll = () => {
    document.getElementById("scrollTo").scrollIntoView({ behavior: "smooth" });
  };

  return (
    <Grid>
      <Button onClick={() => handler(true)} className={classes.viewMoreBut}>
        <Typography
          color="text.secondary"
          sx={{
            fontSize: "12px",
          }}
        >
          More News
        </Typography>
        <ArrowRightAltIcon
          sx={{ fontSize: "22px", color: "rgba(0, 0, 0, 0.6)" }}
        />
      </Button>
      <Drawer anchor={"right"} elevation={20} open={open}>
        <Grid id="scrollTo">
          <div className={classes.storiesHeader}>
            <div className={classes.nestedTopHeader}>
              <span className={classes.topHeader}> MORE NEWS </span>
            </div>
            <Button onClick={() => handler(false)} className={classes.closeBut}>
              <CloseIcon sx={{ fontSize: "24px", color: "white" }} />
            </Button>
          </div>
          {postResizeResults.map((result, index) =>
            index % 5 !== 0 ? (
              <FeedCardFlexReverse
                data={result}
                key={result.objectID}
                drawerOpen={open}
                indexPosition={index}
                queryId={queryId}
              />
            ) : index === 0 ? (
              <FeedCard
                data={result}
                key={result.objectID}
                drawerOpen={open}
                queryId={queryId}
                indexPosition={index}
              />
            ) : (
              <Grid key={result.objectID}>
                <FeedCardFlex
                  data={result}
                  key={result.objectID}
                  drawerOpen={open}
                  queryId={queryId}
                  indexPosition={index}
                />
              </Grid>
            )
          )}
        </Grid>
        {postResizeResults.length < 5 ? null : (
          <Avatar className={classes.showOptions} onClick={handleScroll}>
            <ArrowUpwardIcon fontSize="medium" />
          </Avatar>
        )}
      </Drawer>
    </Grid>
  );
};

export default FeedDrawer;
