import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@material-ui/core/styles";
import truncateHelper from "../../Helpers/truncateHelper";
import Video from "./Video";
import Blog from "./Blog";
import NoContent from "../../NoContent";
import ShareIcons from "../ShareIcons";
import ShareIcon from "@mui/icons-material/Share";
import { useState } from "react";
import { CardActions } from "@mui/material";
import segmentEventClick from "../../Helpers/segmentEventClick";

const useStyles = makeStyles((theme) => ({
  topStoryGrid: {
    paddingLeft: "10px",
    paddingRight: "10px",
  },
  topStoryCardContent: {
    cursor: "pointer",
  },
  topDesc: {
    fontSize: "12px",
    paddingLeft: "10px",
    paddingRight: "10px",
    textAlign: "center",
  },
  topTitle: {
    fontSize: "16px",
    [theme.breakpoints.up("sm")]: {
      fontSize: "20px",
    },
    fontWeight: "bold",
    textAlign: "center",
    display: "flex",
    flexDirection: "column",
  },
  featureFeed: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minWidth: "280px",
  },
  featureVideo: {
    display: "flex",
    flexDirection: "column",
    width: "100%",
    minWidth: "280px",
  },
  featureSponsor: {
    fontSize: "12px",
    fontWeight: "bold",
    color: "#737373",
  },
  share: {
    backgroundColor: "rgb(239,239,239)",
    display: "flex",
    justifyContent: "space-between",
  },
  clickShare: {
    cursor: "pointer",
    color: "#737373",
  },
}));

const TopStoryCard = (results) => {
  const classes = useStyles();
  const [showIcons, setShowIcons] = useState(false);

  const featured = results.results.filter(
    (result) => result.keywords.indexOf("Conference Daily Top Story") !== -1
  );
  const data = featured.length > 0 ? featured : results.results;

  const latest = data.sort((a, b) => {
    return new Date(b.publishDate) - new Date(a.publishDate);
  })[0];

  return (
    <Grid className={classes.topStoryGrid}>
      {latest === undefined || results.results === undefined ? (
        <Grid sx={{ display: "flex", margin: "0 auto" }}>
          <NoContent />
        </Grid>
      ) : (
        <Grid>
          {latest.contentTypesArray.indexOf("Video") !== -1 ? (
            <Video data={latest} />
          ) : null}
          <Card
            className={
              latest.contentTypesArray.indexOf("ATD Blog") !== -1
                ? classes.featureFeed
                : classes.featureVideo
            }
          >
            {latest.contentTypesArray.indexOf("ATD Blog") !== -1 ? (
              <Blog data={latest} />
            ) : null}
            <CardContent
              className={classes.topStoryCardContent}
              onClick={() =>
                segmentEventClick(
                  latest,
                  false,
                  results.algoliaQueryId,
                  1,
                  true
                )
              }
            >
              <Typography className={classes.topTitle}>
                <span> {truncateHelper(latest.title, 10, "title")}</span>
                {latest.contentTypesArray.indexOf("ATD Blog") !== -1 ? (
                  <span className={classes.featureSponsor}>
                    {latest.sponsored ? "Sponsored" : ""}
                  </span>
                ) : null}
              </Typography>
            </CardContent>
            {latest.contentTypesArray.indexOf("Video") !== -1 ? (
              <CardActions className={classes.share}>
                <Typography className={classes.featureSponsor}>
                  {latest.sponsored ? "Sponsored" : ""}
                </Typography>
                <ShareIcon
                  className={classes.clickShare}
                  fontSize="small"
                  onClick={() => setShowIcons(!showIcons)}
                />
              </CardActions>
            ) : null}
          </Card>
          {showIcons ? (
            <ShareIcons dataShare={latest} clickedFunc={setShowIcons} />
          ) : null}
        </Grid>
      )}
    </Grid>
  );
};

export default TopStoryCard;
