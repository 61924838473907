import { createContext, useState } from "react";

//Article **********************

const CheckDrawerArticleContext = createContext();

const CheckDrawerArticleProvider = (props) => {
  const [openArticleDrawer, setOpenArticleDrawer] = useState(false);
  return (
    <CheckDrawerArticleContext.Provider
      value={{ openArticleDrawer, setOpenArticleDrawer }}
    >
      {props.children}
    </CheckDrawerArticleContext.Provider>
  );
};

export { CheckDrawerArticleContext, CheckDrawerArticleProvider };

//Article From Drawer ***********

const CheckArticleFromDrawerContext = createContext();

const CheckArticleFromDrawerProvider = (props) => {
  const [openArticleFromDrawer, setOpenArticleFromDrawer] = useState(false);
  return (
    <CheckArticleFromDrawerContext.Provider
      value={{ openArticleFromDrawer, setOpenArticleFromDrawer }}
    >
      {props.children}
    </CheckArticleFromDrawerContext.Provider>
  );
};

export { CheckArticleFromDrawerContext, CheckArticleFromDrawerProvider };

//Highlight ********************

const CheckDrawerHighlightContext = createContext();

const CheckDrawerHighlightProvider = (props) => {
  const [openHighlightDrawer, setOpenHighlightDrawer] = useState(false);
  return (
    <CheckDrawerHighlightContext.Provider
      value={{ openHighlightDrawer, setOpenHighlightDrawer }}
    >
      {props.children}
    </CheckDrawerHighlightContext.Provider>
  );
};

export { CheckDrawerHighlightContext, CheckDrawerHighlightProvider };

//Highlight From Drawer ********

const CheckHighlightFromDrawerContext = createContext();

const CheckHighlightFromDrawerProvider = (props) => {
  const [openHighlightFromDrawer, setOpenHighlightFromDrawer] = useState(false);
  return (
    <CheckHighlightFromDrawerContext.Provider
      value={{ openHighlightFromDrawer, setOpenHighlightFromDrawer }}
    >
      {props.children}
    </CheckHighlightFromDrawerContext.Provider>
  );
};

export { CheckHighlightFromDrawerContext, CheckHighlightFromDrawerProvider };

//Event ************************

const CheckDrawerEventContext = createContext();

const CheckDrawerEventProvider = (props) => {
  const [openEventDrawer, setOpenEventDrawer] = useState(false);
  return (
    <CheckDrawerEventContext.Provider
      value={{ openEventDrawer, setOpenEventDrawer }}
    >
      {props.children}
    </CheckDrawerEventContext.Provider>
  );
};

export { CheckDrawerEventContext, CheckDrawerEventProvider };

//Event From Drawer *************

const CheckEventFromDrawerContext = createContext();

const CheckEventFromDrawerProvider = (props) => {
  const [openEventFromDrawer, setOpenEventFromDrawer] = useState(false);
  return (
    <CheckEventFromDrawerContext.Provider
      value={{ openEventFromDrawer, setOpenEventFromDrawer }}
    >
      {props.children}
    </CheckEventFromDrawerContext.Provider>
  );
};

export { CheckEventFromDrawerContext, CheckEventFromDrawerProvider };

//Video *************************

const CheckDrawerVideoContext = createContext();

const CheckDrawerVideoProvider = (props) => {
  const [openVideoDrawer, setOpenVideoDrawer] = useState(false);
  return (
    <CheckDrawerVideoContext.Provider
      value={{ openVideoDrawer, setOpenVideoDrawer }}
    >
      {props.children}
    </CheckDrawerVideoContext.Provider>
  );
};

export { CheckDrawerVideoContext, CheckDrawerVideoProvider };

//Alert *************************

const CheckAlertContext = createContext();

const CheckAlertProvider = ({ children }) => {
  const [openAlert, setOpenAlert] = useState(false);
  return (
    <CheckAlertContext.Provider value={{ openAlert, setOpenAlert }}>
      {children}
    </CheckAlertContext.Provider>
  );
};

export { CheckAlertContext, CheckAlertProvider };
