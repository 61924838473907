import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import { useContext, useState } from "react";
import { CheckHighlightFromDrawerContext } from "../../hooks/useContext";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import { imgResize } from "../../Helpers/imgix";

const TemporaryOpenHighlightFromDrawer = (openClickedHighlight) => {
  const { openHighlightFromDrawer, setOpenHighlightFromDrawer } = useContext(
    CheckHighlightFromDrawerContext
  );
  const [state, setState] = useState({
    right: openHighlightFromDrawer,
  });

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const list = (anchor) => (
    <Box sx={{ padding: "10px" }} role="presentation">
      <Button
        onClick={() => {
          toggleDrawer(anchor, false);
          setOpenHighlightFromDrawer(false);
        }}
        sx={{
          "&.MuiButtonBase-root:hover": {
            backgroundColor: "transparent",
          },
        }}
      >
        <KeyboardBackspaceIcon fontSize="large" sx={{ color: "white" }} />
      </Button>
      <Grid
        sx={{
          paddingLeft: "10px",
          paddingRight: "10px",
        }}
      >
        <ImageList cols={1}>
          <ImageListItem>
            <img
              src={`${imgResize(
                openClickedHighlight.clickedHighlightFromDrawer,
                { w: 520, h: 520 }
              )}`}
              alt="conference-snapshot"
              loading="eager"
              rel="preload"
            />
          </ImageListItem>
        </ImageList>
      </Grid>
    </Box>
  );

  return (
    <div>
      <React.Fragment>
        {openHighlightFromDrawer === true ? (
          <Drawer anchor="right" open={state["right"]}>
            {list("right")}
          </Drawer>
        ) : null}
      </React.Fragment>
    </div>
  );
};

export default TemporaryOpenHighlightFromDrawer;
