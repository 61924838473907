import algoliasearch from "algoliasearch";
import { Grid, Slide } from "@mui/material";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import CookieBanner from "./Components/CookieBanner";
import { useEffect, useState } from "react";
import TopStoryCard from "./Components/TopStoryCard";
import Feed from "./Components/Feed";
import axios from "axios";
import ComponentLoader from "./Components/Loader/ComponentLoader";
import Ad from "./Ad";
import Cookies from "universal-cookie/es6";
import ErrorPage from "./Components/Error";
import ReactGA from "react-ga";
import * as Sentry from "@sentry/react";
import NoContent from "./NoContent";

const searchClient = algoliasearch(
  process.env.REACT_APP_ALGOLIA_APP_ID,
  process.env.REACT_APP_ALGOLIA_SECRET
);

const App = () => {
  const [masterResults, setMasterResults] = useState();
  const [noMasterRes, setNoMasterRes] = useState(false);
  const [highlightResults, setHighlightResults] = useState();
  const [queryId, setQueryId] = useState();
  const [masterError, setMasterError] = useState();
  const [highlightError, setHighlightError] = useState();
  const filterIndex = 'keywords:"Conference Daily"';
  let segmentUserId;
  const cookies = new Cookies();
  if (cookies.get("CustomerNumber") !== undefined) {
    segmentUserId = cookies.get("CustomerNumber");
  } else {
    segmentUserId = cookies.get("ajs_anonymous_id");
  }

  const algoliaAnalytics = {
    clickAnalytics: true,
    enablePersonalization: true,
    userToken: segmentUserId,
    hitsPerPage: 1000,
    filters: filterIndex,
  };

  useEffect(() => {
    window.analytics.identify(segmentUserId);
    window.analytics.page("Conference Daily Home Page");
    ReactGA.initialize(process.env.REACT_APP_GA_ID);
    ReactGA.pageview("Conference Daily Home Page");
    const index = searchClient.initIndex("master");

    index
      .search("", algoliaAnalytics)
      .then((hit) => {
        if (hit.hits.length === 0) {
          setNoMasterRes(true);
        }
        setMasterResults(
          hit.hits.filter(
            (res) =>
              res.contentTypesArray.indexOf("Video") !== -1 ||
              res.contentTypesArray.indexOf("ATD Blog") !== -1
          )
        );
        setQueryId(hit.queryID);
      })
      .catch((err) => {
        setMasterError(true);
        Sentry.captureException(new Error(err), {
          tags: {
            section: "Algolia Initial Query Error",
          },
        });
      });

    axios(`/api/getHighlights`)
      .then((response) => {
        if (response.data.type === "GALLERY" && response.status === 200) {
          setHighlightResults(response.data.images);
        }
      })
      .catch((err) => {
        setHighlightError(true);
        Sentry.captureException(new Error(err), {
          tags: {
            section: "Netlify Function Highlights",
          },
        });
      });
  }, []);

  return (
    <Slide direction="up" in={true}>
      <Grid>
          <Grid>
            <Grid>
              <Grid
                sx={{
                  paddingTop: "30px",
                  paddingBottom: "30px",
                  paddingLeft: "10px",
                  paddingRight: "10px",
                }}
              >
                <Header />
              </Grid>
              <Grid
                sx={{
                  paddingBottom: "10px",
                }}
              >
                {masterResults !== undefined && !noMasterRes ? (
                  <TopStoryCard
                    results={masterResults}
                    algoliaQueryId={queryId}
                  />
                ) : noMasterRes && masterResults !== undefined ? (
                  <Grid sx={{ display: "flex", margin: "0 auto" }}>
                    <NoContent />
                  </Grid>
                ) : masterError ? (
                  <ErrorPage />
                ) : (
                  <ComponentLoader />
                )}
              </Grid>
              <Grid
                sx={{
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <Ad />
              </Grid>
              <Grid>
                {masterResults !== undefined ? (
                  <Feed
                    feedResults={masterResults}
                    highlights={highlightResults}
                    algoliaQueryId={queryId}
                    highlightErrorOccured={highlightError}
                  />
                ) : masterError ? (
                  <Feed
                    feedResults={[]}
                    highlights={highlightResults}
                    algoliaQueryId={""}
                    highlightErrorOccured={highlightError}
                  />
                ) : (
                  <ComponentLoader />
                )}
              </Grid>
            </Grid>
            <Grid>
              <Footer />
            </Grid>
          </Grid>
          <CookieBanner />
      </Grid>
    </Slide>
  );
};

export default App;
