import Grid from '@mui/material/Grid'
import { makeStyles } from '@material-ui/core/styles'
import useWindowSize from '../../hooks/useResize'
import { useContext, useState, useRef } from 'react'
import TemporaryHighlightDrawer from './TemporaryHighlighDrawer'
import OpenHighlight from './OpenHighlight'
import { CheckDrawerHighlightContext } from '../../hooks/useContext'
import Slide from '@mui/material/Slide'
import ImageList from '@mui/material/ImageList'
import ImageListItem from '@mui/material/ImageListItem'
import useResizeHighlights from '../../hooks/useResizeHighlights'
import ErrorPage from '../Error'
import { imgResize } from '../../Helpers/imgix'

const useStyles = makeStyles((theme) => ({
  mediaMainHighlights: {
    fontWeight: 'bold',
    letterSpacing: '2px',
    paddingLeft: '10px',
    [theme.breakpoints.down('sm')]: {
      fontSize: '16px',
    },
    [theme.breakpoints.up('sm')]: {
      fontSize: '18px',
    },
  },
}))

const Highlights = (results) => {
  const [openHighlight, setOpenHighlight] = useState()
  const containerRef = useRef(null)
  const classes = useStyles()
  const windowWidth = useWindowSize()
  const { openHighlightDrawer, setOpenHighlightDrawer } = useContext(
    CheckDrawerHighlightContext
  )
  const resizedResults = useResizeHighlights(results.results, windowWidth)

  return (
    <Grid sx={{ paddingBottom: '10px', paddingTop: '10px' }}>
      {resizedResults === undefined ? (
        <ErrorPage />
      ) : (
        <Grid>
          <span className={classes.mediaMainHighlights}> ATD24 Photos </span>
          <Grid
            container
            sx={{
              paddingLeft: '10px',
              paddingRight: '10px',
            }}
          >
            <ImageList cols={resizedResults.column}>
              {resizedResults.mainResults.map((item, index) => (
                <Slide
                  direction="left"
                  in={true}
                  container={containerRef.current}
                  key={index}
                >
                  <ImageListItem key={index}>
                    <img
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        setOpenHighlightDrawer(true)
                        setOpenHighlight(item)
                      }}
                      src={`${imgResize(
                        item
                      )}?w=500&h=500&fit=crop&auto=format`}
                      srcSet={`${imgResize(
                        item
                      )}?w=500&h=500&fit=crop&auto=format&dpr=2 2x`}
                      alt="conference-snapshot"
                      loading="eager"
                      rel="preload"
                    />
                  </ImageListItem>
                </Slide>
              ))}
            </ImageList>
          </Grid>
        </Grid>
      )}
      {resizedResults.showMore === true && resizedResults !== undefined ? (
        <TemporaryHighlightDrawer
          previousResults={resizedResults.mainResults}
          allResults={results}
        />
      ) : null}
      {openHighlightDrawer === true ? (
        <OpenHighlight clickedHighlight={openHighlight} />
      ) : null}
    </Grid>
  )
}

export default Highlights
