import React from "react";
import { DFPSlotsProvider, AdSlot } from "google-react-dfp";
import Grid from "@mui/material/Grid";
import { makeStyles } from "@material-ui/core/styles";

const useStyles = makeStyles(() => ({
  changeColorTrue: {
    color: "rgb(239,239,239)",
    display: "flex",
    justifyContent: "center",
    fontSize: "12px",
  },
  changeColorFalse: {
    display: "flex",
    justifyContent: "center",
    fontSize: "12px",
  },
  adPosition: {
    minWidth: "280px",
  },
}));

const Ad = ({ changeColor }) => {
  const classes = useStyles();

  return (
    <Grid className={classes.adPosition}>
      <DFPSlotsProvider
        dfpNetworkId={"14020868"}
      >
        <AdSlot
          targetingArguments={{ "templateid" : "ConferenceDailyNewsFeed" }}
          adUnit="run-of-network"
          sizes={[[300, 250]]}
        />
      </DFPSlotsProvider>
      <span
        className={
          changeColor === true
            ? classes.changeColorTrue
            : classes.changeColorFalse
        }
      >
        Advertisement
      </span>
    </Grid>
  );
};

export default Ad;
